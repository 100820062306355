import { lazy } from "react";

const Routes = [
  {
    path: "/",
    component: lazy(() => import("../loginpages/Login.js")),
    name: "/",
  },
  {
    path: "/login/admin",
    component: lazy(() => import("../loginpages/Login.js")),
  },
  {
    path: "/plans",
    component: lazy(() => import("../plans/planlist")),
    name: "/admin",
  },
  {
    path: "/editplan",
    component: lazy(() => import("../plans/planedit")),
    name: "/admin",
  },
  {
    path: "/users",
    component: lazy(() => import("../user/userlist")),
    name: "/admin",
  },
  {
    path: "/userdetail",
    component: lazy(() => import("../user/userdetail")),
    name: "/admin",
  },
  {
    path: "/faq",
    component: lazy(() => import("../faqlist/faqlist")),
    name: "/admin",
  },
  {
    path: "/editfaq",
    component: lazy(() => import("../faqlist/editfaq")),
    name: "/admin",
  },
  {
    path: "/addfaq",
    component: lazy(() => import("../faqlist/addfaq")),
    name: "/admin",
  },
  {
    path: "/cmslist",
    component: lazy(() => import("../CMS/cmsbox")),
    name: "/admin",
  },
  {
    path: "/editcms/:data",
    component: lazy(() => import("../CMS/editcms")),
    name: "/admin",
  },
  {
    path: "/editroadmap",
    component: lazy(() => import("../roadmap/editroadmap")),
    name: "/admin",
  },
  {
    path: "/roadmaplist",
    component: lazy(() => import("../roadmap/roadmaplist")),
    name: "/admin",
  },
  {
    path: "/tabs",
    component: lazy(() => import("../Tabs/TabsList")),
    name: "/admin",
  },
  {
    path: "/edittabs",
    component: lazy(() => import("../Tabs/edittabs")),
    name: "/admin",
  },
  {
    path: "/addtabs",
    component: lazy(() => import("../Tabs/addtabs")),
    name: "/admin",
  },
  {
    path: "/contactlist",
    component: lazy(() => import("../contactus/contactuslist.js")),
    name: "/admin",
  },
  {
    path: "/contactusdetails",
    component: lazy(() => import("../contactus/contactusdetails.js")),
    name: "/admin",
  },

  {
    path: "/blog",
    component: lazy(() => import("../blog/bloglist.js")),
    name: "/admin",
  },
  {
    path: "/editblog",
    component: lazy(() => import("../blog/editblog.js")),
    name: "/admin",
  },
  {
    path: "/addblog",
    component: lazy(() => import("../blog/addblog.js")),
    name: "/admin",
  },
  {
    path: "/finance",
    component: lazy(() => import("../finance/Finance.js")),
    name: "/admin",
  },
  {
    path: "/email-template",
    component: lazy(() => import("../EmailTemplate/EmailTemplate.js")),
    name: "/admin",
  },
  {
    path: "/add-email-template",
    component: lazy(() => import("../EmailTemplate/AddEmailTemplate.js")),
    name: "/admin",
  },

  {
    path: "/edit-email-template",
    component: lazy(() => import("../EmailTemplate/EditEmailTemplate.js")),
    name: "/admin",
  },
  {
    path: "/rewards",
    component: lazy(() => import("../rewards/RewardsList.js")),
    name: "/admin",
  },
  {
    path: "/edit-subscription",
    component: lazy(() => import("../rewards/EditSubscription.js")),
    name: "/admin",
  },
  {
    path: "/edit-matchingreward",
    component: lazy(() => import("../rewards/EditMatchingReward.js")),
    name: "/admin",
  },
  {
    path: "/edit-clubreward",
    component: lazy(() => import("../rewards/EditClubReward.js")),
    name: "/admin",
  },
  {
    path: "/edit-teamtrading",
    component: lazy(() => import("../rewards/EditTeamTrading.js")),
    name: "/admin",
  },


  {
    path: "/strategy-exchange",
    component: lazy(() => import("../exchange/exchangelist.js")),
    name: "/admin",
  },
  {
    path: "/add-exchange",
    component: lazy(() => import("../exchange/addExchange.js")),
    name: "/admin",
  },
  {
    path: "/edit-exchange",
    component: lazy(() => import("../exchange/editExchange.js")),
    name: "/admin",
  },


  {
    path: "/strategy-risk",
    component: lazy(() => import("../risk/risklist.js")),
    name: "/admin",
  },
  {
    path: "/add-risk",
    component: lazy(() => import("../risk/addRisk.js")),
    name: "/admin",
  },
  {
    path: "/edit-risk",
    component: lazy(() => import("../risk/editRisk.js")),
    name: "/admin",
  },

  {
    path: "/strategy-pair",
    component: lazy(() => import("../pair/pairlist.js")),
    name: "/admin",
  },
  {
    path: "/add-pair",
    component: lazy(() => import("../pair/addPair.js")),
    name: "/admin",
  },
  {
    path: "/edit-pair",
    component: lazy(() => import("../pair/editPair.js")),
    name: "/admin",
  },


  {
    path: "/bot",
    component: lazy(() => import("../bot/botlist.js")),
    name: "/admin",
  },
  {
    path: "/add-bot",
    component: lazy(() => import("../bot/addBot.js")),
    name: "/admin",
  },
  {
    path: "/edit-bot",
    component: lazy(() => import("../bot/editBot.js")),
    name: "/admin",
  },

  {
    path: "/ticker-list/:id",
    component: lazy(() => import("../ticker/tickerList.js")),
    name: "/admin",
  },
  {
    path: "/edit-ticker",
    component: lazy(() => import("../ticker/editTicker.js")),
    name: "/admin",
  },
];

export default Routes;
