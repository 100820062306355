import { build, buildEnv } from "../config/config";
import { encryptData, decryptData } from "./security"
export const url = {
    landingPage: {
        login: `${build[buildEnv].backendUrl}/user/adminlogin`,
    },
    home: {
        getSubscription: `${build[buildEnv].backendUrl}/subscription`,
        updatePlan:`${build[buildEnv].backendUrl}/subscription`,
        userList:`${build[buildEnv].backendUrl}/user`,
        banUser:`${build[buildEnv].backendUrl}/user`,
        getcms:`${build[buildEnv].backendUrl}/cms`,
        updatecms:`${build[buildEnv].backendUrl}/cms`,
        getcontactUs:`${build[buildEnv].backendUrl}/contactUs`,
        addfaq:`${build[buildEnv].backendUrl}/faq`,
        getFaqs:`${build[buildEnv].backendUrl}/faq`,
        updateFaqs:`${build[buildEnv].backendUrl}/faq`,
        addBlog: `${build[buildEnv].backendUrl}/blog`,
        getBlogs: `${build[buildEnv].backendUrl}/blog`,
        updateBlog: `${build[buildEnv].backendUrl}/blog`,
        checkWallet:`${build[buildEnv].backendUrl}/user/checkwallet`,
        addEmail:`${build[buildEnv].backendUrl}/email`,
        updateEmail:`${build[buildEnv].backendUrl}/email`,
        getEmails:`${build[buildEnv].backendUrl}/email`,
        getcurrency: `${build[buildEnv].backendUrl}/currency`,
        getRewards: `${build[buildEnv].backendUrl}/reward/getrewards`,
        updaterewards: `${build[buildEnv].backendUrl}/reward/`,
        settings:`${build[buildEnv].backendUrl}/setting`
        

    },
    upload:{
        uploadImage:`${build[buildEnv].backendUrl}/fileupload`
    }
}

export const apitrigger = async (url, method, isauthenticate, data) => {
    const payload = {
        method: method,
        headers: { 'Content-Type': 'application/json' },
    };
    if (isauthenticate) {
        payload.headers.Authorization = `Bearer ${localStorage.getItem("token")}`
    }
    if (method !== "GET" && data) {
        payload.body = JSON.stringify({ data: encryptData(data) })
    }
    console.log(payload,"payload")
    let response = await fetch(url, payload);
    response = await response.json();
    response = decryptData(response)
    return response;
}
export const downloadApi = async (url, payload) => {
    let response = await fetch(url, payload);
    response = await response.json();
    response = decryptData(response)
    return response;
}

export const fileUpload = async (url, method, formData) => {
    const payload = {
        method: method,
        body: formData
    }
    let response = await fetch(url, payload);
    response = await response.json();
    return response;
}
export const networkApiCall = async (bnbAmount) => {
    const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd');
    const data = await response.json();
    const bnbToUSDConversionRate = data.binancecoin.usd;
    // const convertedUSDAmount = bnbAmount * bnbToUSDConversionRate;
    return bnbToUSDConversionRate
}