import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse, Dropdown } from "react-bootstrap";
import { Trans } from "react-i18next";
import { GrPlan } from "react-icons/gr";
import { FaUserCircle } from "react-icons/fa";
import { FaQuestionCircle } from "react-icons/fa";
import { RiExchangeFundsLine } from "react-icons/ri";
import { GoProjectRoadmap } from "react-icons/go";
import { TbBrandAirtable } from "react-icons/tb";
import { CiViewList } from "react-icons/ci";
import { MdOutlineContactPhone } from "react-icons/md";
import { FaBlog } from "react-icons/fa6";
import { FaCoins } from "react-icons/fa";
import { MdOutlineAttachEmail } from "react-icons/md";
import { GiAchievement } from "react-icons/gi";
import { DiAsterisk } from "react-icons/di";
import { SiAsterisk } from "react-icons/si";
import { GoDependabot } from "react-icons/go";
import { TbBusinessplan } from "react-icons/tb";
import { MdOutlineCarRepair } from "react-icons/md";
class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/apps", state: "appsMenuOpen" },
      { path: "/basic-ui", state: "basicUiMenuOpen" },
      { path: "/form-elements", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/charts", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  handleReward = () => {
    localStorage.setItem("rewardTab", "subscription");
  };
  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <div className="sidebar-brand-wrapper d-none d-lg-flex align-items-center justify-content-center fixed-top">
          <a className="sidebar-brand brand-logo" href="/plans">
            <img
              src={require("../../assets/images/new_logo.png")}
              alt="logo"
              className="new-logo"
            />
          </a>
          <a className="sidebar-brand brand-logo-mini" href="/plans">
            <img
              src={require("../../assets/images/new_logo.png")}
              alt="logo"
              className="new-logo"
            />
          </a>
        </div>
        <ul className="nav">
          <li className="nav-item nav-category">
            <span className="nav-link">
              <Trans>Navigation</Trans>
            </span>
          </li>
          {/* <li className={ this.isPathActive('/dashboard') ? 'nav-item menu-items active' : 'nav-item menu-items' }> */}
          {/* <Link className="nav-link" to="/dashboard">
              <span className="menu-icon"><i className="mdi mdi-speedometer"></i></span>
              <span className="menu-title"><Trans>Dashboard</Trans></span>
            </Link> */}
          {/* </li> */}

          <li
            className={
              this.isPathActive("/tables")
                ? "nav-item menu-items active"
                : "nav-item menu-items"
            }
          >
            {/* <div className={ this.state.tablesMenuOpen ? 'nav-link menu-expanded' : 'nav-link' } onClick={ () => this.toggleMenuState('tablesMenuOpen') } data-toggle="collapse">
              <span className="menu-icon">
                <i className="mdi mdi-table-large"></i>
              </span>
              <span className="menu-title"><Trans>Tables</Trans></span>
              <i className="menu-arrow"></i>
            </div> */}
            <Collapse in={true}>
              <div>
                <ul className="nav flex-column sub-menu">
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/plan")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/plans"
                    >
                      <Trans>
                        <GrPlan className="mr-2" />
                        Plans
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/users")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/users"
                    >
                      <Trans>
                        <FaUserCircle className="mr-2" />
                        Users
                      </Trans>
                    </Link>
                  </li>

                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/exchange")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/strategy-exchange"
                    >
                      <Trans>
                        <RiExchangeFundsLine className="mr-2" /> Strategy
                        Exchange{" "}
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/strategy-risk")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/strategy-risk"
                    >
                      <Trans>
                        <DiAsterisk className="mr-2" />
                        Strategy Risk
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/strategy-pair")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/strategy-pair"
                    >
                      <Trans>
                        <MdOutlineCarRepair className="mr-2" />
                        Strategy Pair
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/bot")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/bot"
                    >
                      <Trans>
                        <GoDependabot className="mr-2" />
                        Bot
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/faq")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/faq"
                    >
                      <Trans>
                        <FaQuestionCircle className="mr-2" /> Faq{" "}
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/cmslist")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/cmslist"
                    >
                      <Trans>
                        <CiViewList className="mr-2" />
                        Cms
                      </Trans>
                    </Link>
                  </li>
                  {/* <li className="nav-item"> <Link className={ this.isPathActive('/roadmaplist') ? 'nav-link active' : 'nav-link' } to="/roadmaplist"><Trans><GoProjectRoadmap className='mr-2'/>Roadmap</Trans></Link></li> */}
                  {/* <li className="nav-item"> <Link className={ this.isPathActive('/tabs') ? 'nav-link active' : 'nav-link' } to="/tabs"><Trans><TbBrandAirtable className='mr-2'/>Tabs</Trans></Link></li> */}
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/contactlist")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/contactlist"
                    >
                      <Trans>
                        <MdOutlineContactPhone className="mr-2" />
                        Contact us{" "}
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/blog")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/blog"
                    >
                      <Trans>
                        <FaBlog className="mr-2" />
                        Blog
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/finance")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/finance"
                    >
                      <Trans>
                        <FaCoins className="mr-2" />
                        Finance
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      className={
                        this.isPathActive("/email-template")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/email-template"
                    >
                      <Trans>
                        <MdOutlineAttachEmail className="mr-2" />
                        Email Template
                      </Trans>
                    </Link>
                  </li>
                  <li className="nav-item">
                    {" "}
                    <Link
                      onClick={this.handleReward}
                      className={
                        this.isPathActive("/rewards")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      to="/rewards"
                    >
                      <Trans>
                        <GiAchievement className="mr-2" />
                        Rewards
                      </Trans>
                    </Link>
                  </li>
                </ul>
              </div>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
