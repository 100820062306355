export const environment = 'local'
export const buildEnv = 1
export const build = [{
    backendUrl: 'http://localhost:3002/Altranz-bot/v1',
    CRYPTO_SECKEY: "ASWE6YHDU6HUE9783U3U39393",
    imageUrl: 'http://localhost:3002/',
    stripeSecretKey: 'pk_test_51Om9aoSALzhP9YmgSjHOnYeBj6hmBhsgduWqyGE35yKBTsc0N05l13hs7ojfwdofl7B2wi0SsyXMTizm4FEJxQGt00UWs6e5T4',
    chains: [56, 97, 137]
}, {
    backendUrl: 'https://backend-alternance.maticz.in/Altranz-bot/v1',
    CRYPTO_SECKEY: "ASWE6YHDU6HUE9783U3U39393",
    imageUrl: 'https://backend-alternance.maticz.in',
    stripeSecretKey: 'pk_test_51Om9aoSALzhP9YmgSjHOnYeBj6hmBhsgduWqyGE35yKBTsc0N05l13hs7ojfwdofl7B2wi0SsyXMTizm4FEJxQGt00UWs6e5T4',
    chains: [56, 97, 137]
}]
