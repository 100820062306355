import crypto from "crypto-js"
import {build,buildEnv} from "../config/config"
export const encryptData = (data) => {
    const normalText = typeof (data) === 'string' ? data.toString() : JSON.stringify(data);
    return crypto.AES.encrypt(normalText, build[buildEnv].CRYPTO_SECKEY).toString()
}
export const decryptData = (data) => {
    const bytes = crypto.AES.decrypt(data, build[buildEnv].CRYPTO_SECKEY);
    try {
        const decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
        return decryptedData;
    }
    catch (error) {
        if (error.toString() === "SyntaxError: Unexpected end of JSON input") {
            return false;
        } else {
            return bytes.toString(crypto.enc.Utf8);
        }
    }
}

export const getUserData =async () => {
    let response = null
    const userData = localStorage.getItem("userDetails");
    if (userData) {
        response = decryptData(userData)
    }
    return response
}