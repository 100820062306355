import React, { Component, useState, useEffect, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Account_disConnect } from "../../redux/action.js";

import config from "../../lib/config.js";

import close from "../../assets/images/close-icon.png";

import Metamask from "../../assets/images/wallets/metamask.png";
import TrustWalletImage from "../../assets/images/wallets/trust_wallet.png";
import WalletConnect from "../../assets/images/wallets/wallet_connect.png";
import { MetamaskWallet, TrustWallet, WalletConnection ,Disconnect} from "../../hooks/useWallet.js";
import {build,buildEnv} from "../../config/config.js";
import { apitrigger,url } from "../../API/api.services.js";
toast.configure();

export default function Navbar() {
  const [walletShow, setwalletShow] = useState(false);

  const handleWalletClose = () => setwalletShow(false);
  const [text, walletText] = useState("Connect Wallet")
  const handleWalletShow = async() => {
    if (text && text === "Connect Wallet") {
      setwalletShow(true)
    } else {
      await Disconnect();
      window.location.reload()
      toast.success("Wallet disconnected.")
    }
  };
  // functions for modal

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };
  const toggleRightSidebar = () => {
    document.querySelector(".right-sidebar").classList.toggle("open");
  };

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const address = localStorage.getItem("accountInfo");
    if (address) {
      walletText('Disconnect');
    }
  }, [])
  const location = useLocation();
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const Wallet_Details = useSelector((state) => state.wallet_detail);
  const history = useHistory();

  useEffect(() => {
    const address = localStorage.getItem("accountInfo")
    if (address && localStorage.getItem("walletType") === 'Metamask') {
      MetamaskWallet().then(result => {
        dispatch({
          type: "Initial_Connect",
          Initial_Connecting: { web3: result },
        })
      });
    }
    if (address && localStorage.getItem("walletType") === 'Walletconnect') {
      WalletConnection().then(result => {
        dispatch({
          type: "Initial_Connect",
          Initial_Connecting: { web3: result },
        })
      });
    }
    if (address && localStorage.getItem("walletType") === 'TrustWallet') {
      TrustWallet().then(result => {
        dispatch({
          type: "Initial_Connect",
          Initial_Connecting: { web3: result },
        })
      });
    }
  }, [])

  const logout = async () => {
    localStorage.removeItem("token");
    localStorage.removeItem("walletConnectType");
    dispatch({
      type: Account_disConnect,
      Account_Detail_Disconnect: {
        SingleContract: null,
        MultipleContract: null,

        providers: null,

        Accounts: "",
        AddressUserDetails: null,

        load: "false",
      },
    });
    localStorage.setItem("adminlogin", "no");
    history.push("/");
  };
  const metaMaskHandler = async () => {
    // console.log(text,"test")
    // if (text && text === "Connect Wallet") {
      const result = await MetamaskWallet();
      const chainId = await result.eth.getChainId();
      if (result && build[buildEnv].chains.includes(Number(chainId))) {
        console.log("cjjjsjsj")
        dispatch({
          type: "Initial_Connect",
          Initial_Connecting: { web3: result },
        })
        const user = await apitrigger(url.home.checkWallet, 'POST', true, { walletAddress: localStorage.getItem("accountInfo").toLocaleLowerCase() })
        if (user && user.statusCode && user.statusCode === 422) {
          console.log(user, "user");
          localStorage.removeItem("accountInfo");
          toast.error(user.message)
        } else {
          walletText("Disconnect")
          toast.success(user.message)
          handleWalletClose()
          // handleClose()
        }
      } else {
        localStorage.removeItem("accountInfo");
        toast.error("Invalid network")
      }
    // } else {
    //   Disconnect();
    //   toast.success("Wallet disconnected.")
    // }
  }

  const trustwalletHandler = async () => {
    const result = await TrustWallet();
    const chainId = await result.eth.getChainId();
    if (result && build[buildEnv].chains.includes(Number(chainId))) {
      dispatch({
        type: "Initial_Connect",
        Initial_Connecting: { web3: result },
      })
      const user = await apitrigger(url.home.checkWallet, 'POST', true, { walletAddress: localStorage.getItem("accountInfo").toLocaleLowerCase() })
      console.log(user, "user");
      if (user && user.statusCode && user.statusCode === 422) {
        console.log(user, "user");
        localStorage.removeItem("accountInfo");
        toast.failure(user.message)
      } else {
        walletText("Disconnect")
        toast.success(user.message)
        // handleClose()
      }
    } else {
      localStorage.removeItem("accountInfo");
      toast.failure("Invalid network")
    }
  }

  const walletConnecHandler = async () => {
    // setClick(true)
    const wallet = await WalletConnection("", "", "");
    const chainId = await wallet.eth.getChainId();
    if (wallet&& build[buildEnv].chains.includes(Number(chainId))) {
      dispatch({
        type: "Initial_Connect",
        Initial_Connecting: { web3: wallet },
      })
      const user=await apitrigger(url.home.checkWallet,'POST',true,{walletAddress:localStorage.getItem("accountInfo").toLocaleLowerCase()})
      console.log(user,"user");
      if(user&&user.statusCode&&user.statusCode===422){
        console.log(user,"user");
        localStorage.removeItem("accountInfo");
        toast.failure(user.message)
      }else{
        walletText("Disconnect")
        toast.success(user.message)
        // handleClose()
      }
    } else {
      // setClick(false)
      toast.failure("Wallet connect closed.");
    }
  }
  return (
    <>
      <nav
        className={
          location?.pathname == "/"
            ? "navbar loginnavbar p-0 fixed-top d-flex flex-row"
            : "navbar p-0 fixed-top d-flex flex-row"
        }
      >
        {console.log("navbar")}
        <div className="navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
          <Link className="navbar-brand brand-logo-mini" to="/">
            <img
              src={require("../../assets/images/sullamlogo.png")}
              alt="logo"
            />
          </Link>
        </div>
        <div className="navbar-menu-wrapper flex-grow d-flex align-items-stretch">
          <button
            className={
              location?.pathname == "/"
                ? "d-none"
                : "navbar-toggler align-self-center"
            }
            type="button"
            onClick={() => document.body.classList.toggle("sidebar-icon-only")}
          >
            <span className="mdi mdi-menu"></span>
          </button>

          <ul className="navbar-nav navbar-nav-right">
            {!Wallet_Details.UserAccountAddr ? (
              <div className="navbar-profile"></div>
            ) : (
              <Dropdown alignRight as="li" className="nav-item">
                <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                  <h6 className="p-3 mb-0">
                    <Trans>Profile</Trans>
                  </h6>
                  <Dropdown.Divider />
                  <Dropdown.Divider />
                  <Dropdown.Item
                    href="!#"
                    onClick={(evt) => evt.preventDefault()}
                    className="preview-item"
                  >
                    <div className="preview-thumbnail">
                      <div className="preview-icon bg-dark rounded-circle">
                        <i className="mdi mdi-logout text-danger"></i>
                      </div>
                    </div>
                    <div className="preview-item-content">
                      <button
                        onClick={Disconnect}
                        className="preview-subject mb-1 allbtnlogout"
                      >
                        <Trans>Disconnect</Trans>
                      </button>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Divider />
                </Dropdown.Menu>
              </Dropdown>
            )}

            {localStorage.adminlogin == "yes" && (
              <div className="admin_custom_header">
                <button
                  className="allbtn wallet-btn"
                  onClick={handleWalletShow}
                >
                  {text}
                </button>

                <Dropdown alignRight as="li" className="nav-item">
                  <Dropdown.Toggle
                    as="a"
                    className="nav-link cursor-pointer no-caret"
                  >
                    <div className="navbar-profile">
                      <p className="mb-0 d-none d-sm-block navbar-profile-name">
                        <Trans>LOG OUT</Trans>
                      </p>
                      <i className="mdi mdi-menu-down d-none d-sm-block"></i>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="navbar-dropdown preview-list navbar-profile-dropdown-menu">
                    <h6 className="p-3 mb-0">
                      <Trans>Profile</Trans>
                    </h6>
                    <Dropdown.Divider />
                    <Dropdown.Divider />
                    <Dropdown.Item
                      href="!#"
                      onClick={(evt) => evt.preventDefault()}
                      className="preview-item"
                    >
                      <div className="preview-thumbnail">
                        <div className="preview-icon bg-dark rounded-circle">
                          <i className="mdi mdi-logout text-danger"></i>
                        </div>
                      </div>
                      <div className="preview-item-content">
                        <button
                          onClick={() => logout()}
                          className="preview-subject mb-1 btn allbtnlogout"
                        >
                          <Trans>Log out</Trans>
                        </button>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            )}
          </ul>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={toggleOffcanvas}
          >
            <span className="mdi mdi-format-line-spacing"></span>
          </button>
        </div>
      </nav>

      {/* connect wallet modal */}
      <Modal
        show={walletShow}
        onHide={handleWalletClose}
        className="common-modal Wallet-modal"
        centered
        size="sm"
      >
        <div className="custom-modal-body">
          <Button className="close-btn" onClick={handleWalletClose}>
            <img src={close} className="img-fluid" />
          </Button>
          <div className="wallet-list p-2">
            <h5 className="mb-0 fw-700 header-txt">Connect Wallet</h5>
            <div className="mt-4 row d-flex justify-content-center">
              <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 mb-3">
                <div className="wallet-sec" onClick={metaMaskHandler}>
                  <div className="logo d-flex align-items-center justify-content-center">
                    <img src={Metamask} className="img-fluid" />
                  </div>
                  <p className="mb-0 name text-center">Metamask</p>
                </div>
              </div>
              <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 mb-3">
                <div className="wallet-sec" onClick={trustwalletHandler}>
                  <div className="logo d-flex align-items-center justify-content-center">
                    <img src={TrustWalletImage} className="img-fluid" />
                  </div>
                  <p className="mb-0 name text-center">Trust Wallet</p>
                </div>
              </div>
              <div className="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 mb-0">
                <div className="wallet-sec" onClick={walletConnecHandler}>
                  <div className="logo d-flex align-items-center justify-content-center">
                    <img src={WalletConnect} className="img-fluid" />
                  </div>
                  <p className="mb-0 name text-center">Wallet Connect</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
