import Web3 from "web3";
import { CHAIN, environment } from "../config/config";
import { EthereumProvider } from '@walletconnect/ethereum-provider';
// import { CURRENT_CHAIN_ID, CURRENT_RPC_URL } from "./useWeb3";
export const MetamaskWallet = async (e) => {
    // const RPC_URL = CURRENT_RPC_URL();
    // const CHAIN_ID = CURRENT_CHAIN_ID();
    try {
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum);
            await window.ethereum.enable().then(async () => {
                const accounts = await web3.eth.getAccounts();
                const account = accounts[0].toString();
                localStorage.setItem("accountInfo", account)
                localStorage.setItem("walletType", "Metamask")
            });
            return web3;
        }
        // Legacy DApp Browsers
        else if (window.web3) {
            const web3 = new Web3(window.web3.currentProvider);
            const accounts = await web3.eth.getAccounts();
            const rest = await web3.eth.getChainId();
            const account = accounts[0].toString();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem("walletType", "Metamask")
            return web3;
        }
        else {
            console.log("No Dapp Supported Wallet Found")
            alert("No Dapp Supported Wallet Found")
            return "error"
        }
    } catch (e) {
        console.log(e, "error at connection")
        return "error"
    }


}

export const Disconnect = async () => {
    window.ethereum.request({
        method: "eth_requestAccounts",
        params: [
            {
                eth_accounts: {}
            }
        ]
    });
    localStorage.removeItem("accountInfo");
    return true;
}

export const getChainId = async () => {
    // const web3 = new Web3(window.web3.currentProvider);
    const web3=new Web3(window.ethereum)
    const chain = await web3.eth.getChainId();
    return Number(chain)
}

export const TrustWallet = async () => {
   // const RPC_URL = CURRENT_RPC_URL();
    // const CHAIN_ID = CURRENT_CHAIN_ID();
    try {
        if (window.ethereum) {
            const web3 = new Web3(window.ethereum);
            await window.ethereum.enable().then(async () => {
                // User has allowed account access to DApp...
                const accounts = await web3.eth.getAccounts();
                const account = accounts[0].toString();
                localStorage.setItem("accountInfo", account)
                localStorage.setItem("walletType", "TrustWallet")
            });
            return web3;
        }
        // Legacy DApp Browsers
        else if (window.web3) {
            const web3 = new Web3(window.web3.currentProvider);
            const accounts = await web3.eth.getAccounts();
            const rest = await web3.eth.getChainId();
            const account = accounts[0].toString();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem("walletType", "TrustWallet")
            return web3;
        }
        // Non-DApp Browsers
        else {
            console.log("No Dapp Supported Wallet Found")
            alert("No Dapp Supported Wallet Found")
            return "error"
        }
    } catch (e) {
        console.log(e, "error at connection")
        return "error"
    }

}


export const WalletConnection = async (type, changechainid, status) => {
    const CHAIN_ID = 97;
    if (status && status == 'switched') {
        try {
            // const hexString = CHAIN_ID.toString(16);

            // console.log("vkfjkvfb", hexString, changechainid);

            const provider = await EthereumProvider.init({
                projectId: '00f5adb7dc74237dce44e66c1c1c876e', // required
                chains: [CHAIN_ID], // required
                optionalChains: [1, 56],
                showQrModal: true // requires @walletconnect/modal
            })
            await provider.enable()
            console.log("flkkfbk", provider);
            // await provider.connect()


            var web3 = new Web3(provider);
            const accounts = await provider.request({
                method: 'wallet_switchEthereumChain',
                //   params: [{ chainId: "0x" + hexString }],
            });
            console.log("kjdfksk", accounts);
            // var account = accounts[0]?.toString();
            // localStorage.setItem("accountInfo", account)
            //   localStorage.setItem('walletConnectType', type)
            return web3;
        }
        catch (err) {
            console.log("ffddfbdfb", err);
        }
    }

    if (localStorage.getItem("accountInfo")) {
        try {
            const provider = await EthereumProvider.init({
                projectId: '00f5adb7dc74237dce44e66c1c1c876e', // required
                chains: [CHAIN_ID], // required
                optionalChains: [1, 56],
                showQrModal: true // requires @walletconnect/modal
            })
            await provider.enable()

            var web3 = new Web3(provider);
            const accounts = await provider.request({
                method: 'eth_requestAccounts',
            });
            var account = accounts[0].toString();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem('walletType', 'Walletconnect')
            return web3;
        }
        catch (err) {
            console.log(err, "error");
            // toast.warning(err.toString())
        }
    }
    else {
        try {
            const provider = await EthereumProvider.init({
                projectId: '00f5adb7dc74237dce44e66c1c1c876e', // required
                chains: [97], // required
                optionalChains: [1, 56],
                showQrModal: true // requires @walletconnect/modal
            })
            await provider.connect()
            var web3 = new Web3(provider);
            const accounts = await provider.request({
                method: 'eth_requestAccounts',
            });
            var account = accounts[0].toString();
            localStorage.setItem("accountInfo", account)
            localStorage.setItem('walletType', 'Walletconnect')

            return web3;
        }
        catch (err) {
            console.log(err, "errororororo");
            // toast.warning(err.toString())
            return ({})
        }
    }

}

